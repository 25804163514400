/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import RespuestaForm from '../RespuestaForm/RespuestaForm';
// import Solutions from '../../Home/2-Body/3-Solutions/Solutions';
import './FormEmObligada.css';
import api from '../../../../api';

export default class FormEmObligada extends React.Component {

  constructor(props){
    super(props)
    this.state={isVerified:false};
    this.state={
      ruc:"",
      mostrar:false,
      consulta:{
        empresa:[],
        mensaje:undefined,
      }};
    this.handleChange=this.handleChange.bind(this);
    this.consultarEmpresa=this.consultarEmpresa.bind(this);
  }
  handleChange(e) {
    this.setState({[e.target.name]:e.target.value})
    this.setState({mostrar:false})
  }
  async consultarEmpresa(e) {
    e.preventDefault();
    try{
      const consultar = await api.EmpresaObligada.consultarEmpresa(this.state.ruc);
      // console.log(consultar.mensaje);
      this.setState({mostrar:true,
        consulta:{empresa:[].concat(this.state.consulta,consultar.empresa)},
        mensaje:{mensaje:[].concat(this.state.consulta,consultar.mensaje)},
       
      });
      this.state.consulta.mensaje=consultar.mensaje;
      console.log(this.state.consulta.empresa);
    }catch(error){
      console.error('Error',error)
      this.setState({mostrar:false});

    }
  }
  render() {
    return (
      <div className="FormEmObligada">
          <div>
            <h1>Verifica si tu empresa esta obligada a facturar electronicamente</h1>
            <form onSubmit={this.props.onSubmit}>
              <div className="FormEmObligada__2Columnas">
                <div>
                  <label>
                    RUC:
                    {' '}
                    {' '}
                  </label>
                  <input type="text" onChange={this.handleChange} value={this.state.ruc} name="ruc" id="ruc" placeholder="Ingrese su Ruc" minLength="11" maxLength="11" required />
                </div>
                <div>
                  <button type="submit" className="FormEmObligada__button" onClick={this.consultarEmpresa}>Buscar</button>
                </div>
              </div>
            </form>
            <div>
            </div>
            <div>

              <RespuestaForm
              consultado={this.state.mostrar}
              REmpresa={this.state.consulta}
              />
           
            </div>
          </div>
      </div>
    );
  }
}
