import React from 'react';
import './LibroReclamaciones.css'
import imagen from './Reclamaciones.jpg';
class RegistroForm extends React.Component{
render(){
    return(
        <div className="FormReclamaciones">
       <div className="Form_encabezado">
        <h1>Libro de Reclamaciones</h1>
        <img src={imagen} alt='libro reclamaciones'/>
        </div>
        <form onSubmit={this.props.onSubmit}>
            <div>
                <p>Datos Personales</p>
                <hr/>
                <div>
                    <div>
                        <label>¿Menor de Edad?</label>
                    </div>
                    <div className="Form_filas" id="menorEdad" 
                        value={this.props.form.menorEdad} 
                        onChange={this.props.onChange} required>
                    <div>
                        <input type="radio" name="menorEdad" value="SI"/><label>  SI</label>
                    </div>
                    <div>
                       <input type="radio" name="menorEdad"  value="NO"/> <label>NO</label>
                    </div>

                    </div>
                </div>
                <div>
                {this.props.form.menorEdad==="SI"?
                    <div>
                       <p>Datos del Apoderado</p>
                       <hr/>
                       <div>
                            <div>
                                <label>
                                    DNI
                                </label>   
                            </div>
                            <div>
                                <input
                                 id="dni_ruc" 
                                 value={this.props.form.dniApoderado} 
                                 onChange={this.props.onChange}
                                 name="dniApoderado" 
                                 placeholder="Ingrese su numero de documento"
                                 type="char"
                                 maxLength="8"
                                 minLength="8"

                                />
                                <div className="errorMsg">{this.props.errors.dniApoderado}</div>
                            </div>
                         </div>
                         <div className="Form_colum">
                         <div>
                            <label>
                                Nombres
                            </label>
                            <div>
                                <input type="text" id="nombreApoderado" 
                                maxLength="25"
                                minLength="3"
                                value={this.props.form.nombreApoderado} 
                                onChange={this.props.onChange} name="nombreApoderado" placeholder="Ingrese sus nombres"/>
                                <div className="errorMsg">{this.props.errors.nombreApoderado}</div>
                            </div>
                        </div>
                        <div>
                            <label>
                                Primer apellido
                            </label>
                            <div>
                                <input type="text" id="primerApellidoApo" 
                                value={this.props.form.primerApellidoApo}
                                onChange={this.props.onChange} 
                                name="primerApellidoApo"
                                placeholder="Ingrese primer apellido"
                                maxLength="25"
                                minLength="3"
                                />
                                <div className="errorMsg">{this.props.errors.primerApellidoApo}</div>
                            </div>
                        </div>
                        <div>
                            <label>
                                Segundo apellido
                            </label>
                            <div>
                                <input type="text" id="segundoApellidoApo" 
                                value={this.props.form.segundoApellidoApo}
                                 onChange={this.props.onChange} 
                                 name="segundoApellidoApo"
                                 placeholder="Ingrese segundo apellido" 
                                 maxLength="25"
                                 minLength="3"/>
                                <div className="errorMsg">{this.props.errors.segundoApellidoApo}</div>
                            </div>
                        </div>
                     </div> 
                    </div> :this.props.form.menorEdad==="NO"? null:null} 
                </div>

                <div>
                    <p>Datos del Solicitante</p>
                    <hr/>
                    <div>
                        <label>
                            DNI
                        </label>   
                    </div>
                    <div>
                        <input  type="char" id="dni_ruc" 
                        value={this.props.form.dni} 
                        onChange={this.props.onChange} 
                        name="dni" 
                        placeholder="Ingrese su numero de documento"
                        maxLength="8"
                        minLength="8"/>
                         <div className="errorMsg">{this.props.errors.dni}</div>
                    </div>
                </div>
                   <div className="Form_colum">
                         <div>
                            <label>
                                Nombres
                            </label>
                            <div>
                                <input type="text" 
                                id="nombre" 
                                value={this.props.form.nombre}
                                onChange={this.props.onChange}
                                name="nombre"
                                placeholder="Ingrese sus nombres" 
                                maxLength="25"
                                minLength="3"
                               />
                               <div className="errorMsg">{this.props.errors.nombre}</div>
                            </div>
                        </div>
                        <div>
                            <label>
                                Primer apellido
                            </label>
                            <div>
                                <input type="text"
                                 id="primerApellido" 
                                 value={this.props.form.primerApellido}
                                 onChange={this.props.onChange} 
                                 name="primerApellido"
                                 placeholder="Ingrese primer apellido"
                                 maxLength="25"
                                 minLength="3" />
                                <div className="errorMsg">{this.props.errors.primerApellido}</div>
                            </div>
                        </div>
                        <div>
                            <label>
                                Segundo apellido
                            </label>
                            <div>
                                <input type="text"
                                 id="segundoApellido"
                                value={this.props.form.segundoApellido}
                                onChange={this.props.onChange}
                                name="segundoApellido"
                                placeholder="Ingrese segundo apellido"
                                maxLength="25"
                                minLength="3"
                                />
                                <div className="errorMsg">{this.props.errors.segundoApellido}</div>
                            </div>
                        </div>
                     </div> 

            </div>
            <div>
                <div>
                    <label>¿Representa a una empresa?</label>
                </div>
                <div className="Form_filas"
                 id="Empresa" 
                 value={this.props.form.repreEmpresa} 
                 onChange={this.props.onChange} required>
                <div>
                   <input type="radio" name="repreEmpresa" value="SI" /><label> SI</label>
                </div>
                <div>
                    <input type="radio" name="repreEmpresa"  value="NO" /><label>NO</label>
                </div>

                </div>
            </div>

            <div>
                {this.props.form.repreEmpresa==="SI"? 
                    <div className="Form_colum">
                    <div>
                       <label>
                         RUC/RUS
                       </label>
                       <div>
                           <input type="text"
                            id="ruc_rus" 
                            value={this.props.form.ruc_rus}
                            onChange={this.props.onChange} 
                            name="ruc_rus" 
                            maxLength="11"
                            minLength="11"
                            placeholder="Ingrese su RUC/RUS"/>
                             <div className="errorMsg">{this.props.errors.ruc_rus}</div>
                       </div>
                   </div>
                    <div>
                       <label>
                        Razon Social
                       </label>
                       <div>
                           <input type="text" 
                           id="razonSocial" 
                           value={this.props.form.razonSocial}
                           onChange={this.props.onChange}
                           name="razonSocial"
                           placeholder="Ingrese su razon Social"
                           minLength="3"/>
                           <div className="errorMsg">{this.props.errors.razonSocial}</div>
                       </div>
                   </div>
                </div> 
                 :this.props.form.repreEmpresa==="NO"? null:null} 
            </div>

            <div>
                <p>Datos del Contacto</p>
                <hr/>
                <div className="Form_colum">
                    <div>
                        <label>
                            E-mail
                        </label>
                        <div>
                            <input type="email"
                             id="email"
                             name="email" 
                             value={this.props.form.email} 
                             onChange={this.props.onChange} 
                             placeholder="Ingrese su email"/>
                            <div className="errorMsg">{this.props.errors.email}</div>
                        </div>
                    </div>
                    <div>
                        <label>
                            Celular/Teléfono
                        </label>
                        <div>
                            <input  type="char"
                            id="celular"
                            name="celular"
                            value={this.props.form.celular}
                            onChange={this.props.onChange} 
                            placeholder="Ingrese su celular" 
                            maxLength="11"
                            />
                          <div className="errorMsg">{this.props.errors.celular}</div>
                        </div>
                    </div>
                    <div>
                        <label>
                            Domicilio
                        </label>
                        <div>
                            <input type="text"
                             id="domicilio"
                             name="domicilio" 
                             value={this.props.form.domicilio}
                             onChange={this.props.onChange}
                             maxLength="60"
                             minLength="3"
                             placeholder="Ingrese su domicilio"/>
                             <div className="errorMsg">{this.props.errors.domicilio}</div>
                        </div>
                    </div>
                </div>
               
            </div>
            <div>
                <p>Detalles del Reclamo</p>
                <hr/>
                <div className="Form_colum">
                    <div>
                        <label>Producto/Servicio
                        <select name="resProduServi"
                         value={this.props.form.resProduServi}
                         onChange={this.props.onChange} required>
                            
                            <option name="resProduServi" value="Servicio">Servicio</option>
                            <option name="resProduServi" value="Producto">Producto</option>   
                        </select>
                        </label>
                    </div>
                    <div>
                        <label>
                            Motivo
                        </label>
                        <div>
                            <input type="text"
                             id="motivo"
                             name="motivo"
                             value={this.props.form.motivo}
                             onChange={this.props.onChange} 
                             maxLength="250"
                             minLength="10"
                             placeholder="Ingrese motivo"
                            />
                             <div className="errorMsg">{this.props.errors.motivo}</div>
                        </div>
                    </div>
                   
                </div>
                <div>
                    <div>
                        <label>
                            Pedido
                        </label>
                        <div>
                            <input className="FormReclamaciones-input__textArea" id="pedido" name="pedido"
                             value={this.props.form.pedido}
                             onChange={this.props.onChange}
                             maxLength="250"
                             minLength="10"
                             placeholder="Ingrese el pedido"
                             />
                              <div className="errorMsg">{this.props.errors.pedido}</div>
                        </div>
                    </div>
                    <div>
                        <label>
                            Detalle
                        </label>
                        <div>
                            <input className="FormReclamaciones-input__textArea" id="detalle" name="detalle"
                             value={this.props.form.detalle} 
                             onChange={this.props.onChange}
                             maxLength="250"
                             minLength="10"
                             placeholder="Ingrese el detalle"
                             />
                              <div className="errorMsg">{this.props.errors.detalle}</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label>Tipo</label>
                        </div>
                        <div className="Form_filas" 
                        id="Tipo"
                        value={this.props.form.respTipo}
                        onChange={this.props.onChange} required>
                        <div>
                           <input type="radio" name="respTipo" value="Reclamo"/><label>Reclamo</label> 
                        </div>
                        <div>
                            <input type="radio"  name="respTipo"  value="Queja"/><label>Queja</label>
                        </div>

                        </div>
                  </div>
                </div>
            </div>
           
           <div>
           <button>Enviar</button>
        </div> 
            {this.props.error && (
                 <p className="errorMsg">{this.props.error.message}</p>
            )}
        </form>
       
    </div>
        )
    }
}

export default RegistroForm;