/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';

// Resources
import './MenuList.css';

export default function MenuList() {
  return (
    <ul className="menu-list">
      <li><a href="/#Precios">Precios</a></li>
      <li><a href="/#Beneficios">Beneficios</a></li>
      <li><a href="/soluciones">Soluciones</a></li>
      <li><a href="/blog">Blog</a></li>
      <li><a href="/contacto">Contacto</a></li>
      <li><a href="/libro-reclamaciones">Reclamaciones</a></li>
      <li><a href="http://consultas.ifacturacion.pe/" target="_blank">Reportes</a></li>
    </ul>
  );
}
