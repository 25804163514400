const BASE_URL = 'https://apis.consultasruc.com:4000';
// const BASE_URL = 'http://localhost:3000';
// // http://papi.hopto.org:3000
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const randomNumber = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1)) + min;

const simulateNetworkLatency = (min = 30, max = 1500) => delay(randomNumber(min, max));

async function callApi(endpoint, options = {}) {
  await simulateNetworkLatency();

  // eslint-disable-next-line no-param-reassign
  options.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();

  return data;
}

const api = {
  usuario: {
    enviarCorreoVentas(datos) {
      return callApi('/api/mails/ifacturacion', { // enviar correo de infor palerp los datos de la persona que son nombre,correo mensaje
        method: 'POST',
        body: JSON.stringify(datos),
      });
    },
    enviarCorreoRegistro(datos) {
      return callApi('/api/mails/ifacturacionRegistro', { // enviar correo de infor palerp los datos de la persona que son nombre,correo mensaje
        method: 'POST',
        body: JSON.stringify(datos),
      });
    },
    enviarCorreoEncuesta(datos){
      return callApi('/api/mails/iFacturacionEncuesta',{
        method:'POST',
        body: JSON.stringify(datos)
      });
    }
  },
  DescargaComprobante: {
    descargarPDF(datos) {
      return callApi('/api/AArchivo/comprobantespdf', {
        method: 'POST',
        body: JSON.stringify(datos),
      });
    },
    DownloadArchivoXmlCdr(encript) {
      return callApi(`/api/AArchivo/url/${encript}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
  SubidaArchivos:{
    subirArchivos(datos){
      return callApi('/api/AArchivo/subidaArchivo',{
        enctype:'multipart/form-data',
        method:'POST',
        body:JSON.stringify(datos),
      });
    },
    listarArchivos(){
      return callApi('/api/AArchivo/listarArchivos');
    }
  },
  EmpresaObligada: {
    consultarEmpresa(ruc) {
      return callApi(`/api/AEmpresaObligada/${ruc}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
  libroReclamaciones:{
    guardarReclamo(datos){
      return callApi('/api/AIncidentes/save', {
          method: 'POST',
          body: JSON.stringify(datos),
        });
   },
   traerNroIncidencia(datos){
    return callApi('/api/AIncidentes/listarUno', {
      method: 'POST',
      body: JSON.stringify(datos),
    });
   },
   creacionPDF(datos){
    return callApi('/api/mails/createPDFNuevo', {
        method: 'POST',
        body: JSON.stringify(datos),
      });
    },
    enviarEmail(datos){
        return callApi('/api/mails/emailCliente', {
        method: 'POST',
        body: JSON.stringify(datos),
      });
    }
  },
  clienteProveedor:{
    guardarClienteProvedor(datos){
      return callApi('/api/AIncidentes/save/cliente-Proveedor', {
          method: 'POST',
          body: JSON.stringify(datos),
        });
   },
  
  
  }
};

export default api;
