import React from 'react';
//Resources
import imagen from './Reclamaciones.jpg';
import './LibroReclamaciones.css';
import api from '../../../../api';
import FormReclamaciones from './FormReclamaciones';
export default class LibroReclamaciones extends React.Component{
    constructor(props){
        super(props)
        this.state={
            form:{
                fecha:'',
                numeroReclamaciones:'',
                nombre:'',
                primerApellido:'',
                segundoApellido:'',
                domicilio:'',
                dni:'',
                email:'',
                celular:'',
                representante:'',
                resProduServi:'',
                motivo:'',
                respTipo:'',
                pedido:'',
                detalle:'',
                menorEdad:'',
                dniApoderado:'',
                nombreApoderado:'',
                primerApellidoApo:'',
                segundoApellidoApo:'',
                repreEmpresa:'',
                razonSocial:'',
                ruc_rus:'',
            },
            error:null,
            errors:{},

            guardarReclamo:{
                resultado:[]
                },
           
            Nro_Incidencia:{
                resultado:[]
            },
            crearPDF:{
                resultado:[]
            },
            email:{
             resultado:[]
            },
            mostrar:false,
        }
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    validateForm() {

        let form = this.state.form;
        let errors = {};
        let formIsValid = true;
  
        if (!form["nombre"]) {
          formIsValid = false;
          errors["nombre"] = "*Porfavor ingrese su nombre .";
        }
  
        if (typeof form["nombre"] !== "undefined") {
            var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
            if (!pattern.test(form["nombre"])) {
              formIsValid = false;
              errors["nombre"] = "*Ingrese  letras del alfabeto porfavor.";
            }
          }
          if (!form["primerApellido"]) {
            formIsValid = false;
            errors["primerApellido"] = "*Porfavor ingrese su apellido paterno.";
          }
    
          if (typeof form["primerApellido"] !== "undefined") {
              var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
              if (!pattern.test(form["primerApellido"])) {
                formIsValid = false;
                errors["primerApellido"] = "*Ingrese letras del alfabeto porfavor.";
              }
            }
        if (!form["segundoApellido"]) {
            formIsValid = false;
            errors["segundoApellido"] = "*Porfavor ingrese su apellido materno  .";
            }
    
        if (typeof form["segundoApellido"] !== "undefined") {
            var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
            if (!pattern.test(form["segundoApellido"])) {
            formIsValid = false;
            errors["segundoApellido"] = "*Ingrese letras del alfabeto porfavor.";
            }
        }

        if (!form["domicilio"]) {
            formIsValid = false;
            errors["domicilio"] = "*Porfavor ingrese su domicilio .";
          }

        
        if (!form["dni"]) {
        formIsValid = false;
        errors["dni"] = "*Porfavor ingrese su DNI .";
        }
    
        if (typeof form["dni"] !== "undefined") {
        if (!form["dni"].match(/^[0-9]{8}$/)) {
            formIsValid = false;
            errors["dni"] = "*Ingrese solo números.";
        }
        }

        if (!form["email"]) {
            formIsValid = false;
            errors["email"] = "*Ingrese su correo Electronico.";
          }
    
          if (typeof form["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(form["email"])) {
              formIsValid = false;
              errors["email"] = "*Ingrese de nuevo su correo Electronico.";
            }
          }
    
          if (!form["celular"]) {
            formIsValid = false;
            errors["celular"] = "*Porfavor ingrese su celular/telefono .";
          }
    
          if (typeof form["celular"] !== "undefined") {
            if (!form["celular"].match(/^[0-9]{9,11}$/)) {
              formIsValid = false;
              errors["celular"] = "*Ingrese 9 números del celular o los 8 números del telefono.";
            }
          }
        
    
          if (form["dniApoderado"].length>=1) {
            if (!form["dniApoderado"].match(/^[0-9]{8}$/)) {
              formIsValid = false;
              errors["dniApoderado"] = "*Ingrese solo números.";
            }
          }
       
          if (form["nombreApoderado"].length > 1) {
              var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
              if (!pattern.test(form["nombreApoderado"])) {
                formIsValid = false;
                errors["nombreApoderado"] = "*Ingrese letras del alfabeto porfavor.";
              }
            }
          
        
            if (form["primerApellidoApo"].length > 1) {
                var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
                if (!pattern.test(form["primerApellidoApo"])) {
                formIsValid = false;
                errors["primerApellidoApo"] = "*Ingrese letras del alfabeto porfavor.";
                }
            }
        
            if (form["segundoApellidoApo"].length > 1) {
                var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
                if (!pattern.test(form["segundoApellidoApo"])) {
                formIsValid = false;
                errors["segundoApellidoApo"] = "*Ingrese letras del alfabeto porfavor.";
                }
            }
         
            if (form["ruc_rus"].length >1) {
                if (!form["ruc_rus"].match(/^[0-9]{11}$/)) {
                    formIsValid = false;
                    errors["ruc_rus"] = "*Ingrese 11 digitos.";
                }
            }
          
            if (form["razonSocial"].length>1) {
              var pattern = new RegExp(/^[a-zA-Z-0-9\s]+$/g);
              if (!pattern.test(form["razonSocial"])) {
              formIsValid = false;
              errors["razonSocial"] = "*Ingrese nombre de la razón social.";
              }
          }
          if (!form["motivo"]) {
            formIsValid = false;
            errors["motivo"] = "*Ingrese el motivo  .";
            }
        
        if (form["motivo"].length < 9) {
            var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
            if (!pattern.test(form["motivo"])) {
            formIsValid = false;
            errors["motivo"] = "*Ingrese minimo 10 caracteres";
            }
        }
        if (!form["pedido"]) {
          formIsValid = false;
          errors["pedido"] = "*Ingrese su pedido .";
          }
          if (form["pedido"].length < 9 ){
          var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
          if (!pattern.test(form["segundoApellido"])) {
          formIsValid = false;
          errors["pedido"] = "*Ingrese minimo 10 caracteres.";
          }
        }
        if (!form["detalle"]) {
          formIsValid = false;
          errors["detalle"] = "*Porfavor ingrese el detalle  .";
          }
  
          if (form["detalle"].length < 9){
          var pattern = new RegExp(/^[a-zA-Z\s]+$/g);
          if (!pattern.test(form["detalle"])) {
          formIsValid = false;
          errors["detalle"] = "*Ingrese minimo 10 caracteres.";
          }
      }
          
        this.setState({errors: errors});
        return formIsValid;
      }

  handleChange(e){
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });

  }

  async handleSubmit(e){
      e.preventDefault();
      if (this.validateForm()) {
        let form = {};
        form["nombre"] = "";
        form["primerApellido"] = "";
        form["segundoApellido"] = "";
        form["domicilio"]="";
        form["dni"] = "";
        form["email"] = "";
        form["celular"] = "";
        form["dniApoderado"]="";
        form["nombreApoderado"] = "";
        form["primerApellidoApo"] = "";
        form["segundoApellidoApo"] = "";
        form["ruc_rus"]="";
        form["razonSocial"]="";
        form["motivo"] = "";
        form["pedido"]="";
        form["detalle"]="";
        this.setState({form:form});
    }
    const validacion=this.validateForm();
    // console.log(validacion)
       
            if(validacion===true){
            try{
            var fechaActual=new Date();
            this.state.form.fecha=fechaActual;
            // console.log(this.state.form.fecha);
            const nombreCompleto=this.state.form.nombre+' '+this.state.form.primerApellido+' '+this.state.form.segundoApellido
            const Celular=this.state.form.celular;
            const Detalle=this.state.form.detalle;
            const Motivo=this.state.form.motivo;
            const RespTipo=this.state.form.respTipo;
            const Domicilio=this.state.form.domicilio;
            const DNI=this.state.form.dni;
            const Email=this.state.form.email;
            const ResProduServi=this.state.form.resProduServi;
            const Pedido=this.state.form.pedido;
            const DniApoderado=this.state.form.dniApoderado;
            const NombreCompletoApoderado=this.state.form.nombreApoderado+' '+this.state.form.primerApellidoApo+' '+this.state.form.segundoApellidoApo
            const RazonSocial=this.state.form.razonSocial;
            const Ruc_Rus=this.state.form.ruc_rus;
            var tipoIncidencia='';

            if(this.state.form.resProduServi==='producto'){
                tipoIncidencia='301';
            }else{
                tipoIncidencia='201';
            }   
            // console.log(tipoIncidencia);
            const variables={
                procedure:'USP_SOP_INCIDENCIAS_G',
                parametros:[
                    {nom_parametro:'Id_Incidencia',valor_parametro:''},
                    {nom_parametro:'Nro_Incidencia',valor_parametro:''},
                    {nom_parametro:'Id_ClienteProveedor',valor_parametro:'105684'},
                    {nom_parametro:'Id_Terminal',valor_parametro:''},
                    {nom_parametro:'Cod_TipoIncidencia',valor_parametro:tipoIncidencia},
                    {nom_parametro:'Cod_Prioridad',valor_parametro:'003'},
                    {nom_parametro:'Cod_Complejidad',valor_parametro:'003'},
                    {nom_parametro:'Nro_Contacto',valor_parametro:Celular},
                    {nom_parametro:'Nom_Contacto',valor_parametro:nombreCompleto},
                    {nom_parametro:'Detalle',valor_parametro:Detalle},
                    {nom_parametro:'Respuesta',valor_parametro:''},
                    {nom_parametro:'Cod_MedioOrigen',valor_parametro:'006'},
                    {nom_parametro:'Obs_Incidencia',valor_parametro:Motivo},
                    {nom_parametro:'Cod_Estado',valor_parametro:'001'},
                    {nom_parametro:'Fecha_Inicia',valor_parametro:fechaActual},
                    {nom_parametro:'Fecha_Finaliza',valor_parametro:''},
                    {nom_parametro:'Cod_UsuarioIncidencia',valor_parametro:'WEB'},
                    {nom_parametro:'Cod_TipoEncuesta',valor_parametro:RespTipo},
                    {nom_parametro:'Pregunta1',valor_parametro:''},
                    {nom_parametro:'Pregunta2',valor_parametro:''},
                    {nom_parametro:'Pregunta3',valor_parametro:''},
                    {nom_parametro:'Cod_Usuario',valor_parametro:'WEB'},
                ]
            }
            // console.log(variables);
            const reclamo= await api.libroReclamaciones.guardarReclamo(variables); 
            // console.log(reclamo);
            // console.log(reclamo.message);
            this.setState({
                guardarReclamo:{resultado:[].concat(this.state.guardarReclamo,reclamo.resultado)}
            });
            if(reclamo.message==="datoGuardado"){
                const datosReclamo={
                procedure:'USP_SOP_INCIDENCIAS_TraerNroIncidencia',
                parametros:[
                    {nom_parametro:"Nom_Contacto",valor_parametro:nombreCompleto},
                    {nom_parametro:"Fecha_Inicia",valor_parametro:fechaActual}
                    ]
                }
            //  console.log(datosReclamo);
             const nroIncidencia=await api.libroReclamaciones.traerNroIncidencia(datosReclamo);
            //  console.log(nroIncidencia);
             this.setState({
                Nro_Incidencia:{resultado:[].concat(this.state.Nro_Incidencia,nroIncidencia.resultado)}
             });
             const incidenciaNro=nroIncidencia.resultado["0"].Nro_Incidencia;
            
                const datosPDF={
                    fecha:fechaActual,
                    numeroReclamaciones:incidenciaNro,
                    nombreTitularCompleto:nombreCompleto,
                    domicilo:Domicilio,
                    dni:DNI,
                    email:Email,
                    celular:Celular,
                    resProduServi:ResProduServi,
                    respTipo:RespTipo,
                    motivo:Motivo,
                    pedido:Pedido,
                    detalle:Detalle,
                    dniApoderado:DniApoderado,
                    nombreCompletoApoderado:NombreCompletoApoderado,
                    razonSocial:RazonSocial,
                    ruc_rus:Ruc_Rus
             }
            const crearPDF= await api.libroReclamaciones.creacionPDF(datosPDF);
            // console.log(crearPDF);
            this.setState({
                crearPDF:{resultado:[].concat(this.state.crearPDF,crearPDF.resultado)}
            });

            if(crearPDF.message==="creadoPDF"){
            
            const datosEmail={
                numeroReclamaciones:incidenciaNro,
                nombre:nombreCompleto,
                email:Email

            }
            const email=await api.libroReclamaciones.enviarEmail(datosEmail);
            // console.log(email);
            this.setState({
                email:{resultado:[].concat(this.state.email,email.resultado)}
            });
            if(email.message==="EnviadoEmail"){
        
                window.location.href = '/'; 
        
              }

            }else{

                console.log("No se pudo crear el pdf");
                this.setState({error: 'error'});
             }
            }
            else{
                console.log("No se pudo guardar los datos");
                this.setState({error: 'error'});  
            }
        }catch(error){
          console.log('Error',error);
          this.setState({error: 'error'});
          // alert('revise los datos porfavor hubo un error');
      
        }
      }
      else{
        this.setState({error: 'error'});
      }

    }
    render(){
    return(
        <div className="FormReclamaciones">
            <section>
                        <FormReclamaciones  onChange={this.handleChange}
                                  onSubmit={this.handleSubmit}
                                  form={this.state.form}
                                  errors={this.state.errors}
                                  />
            </section>
        </div>
        )
    }
}